<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>重点关注信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="住户">
                <div style="width: 500px;">
                  {{ formData.realName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户手机">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="区域">
                <div style="width: 500px;">
                  {{ formData.areaCodeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="关注类型">
                <div style="width: 500px;">
                  {{ formData.focusType }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="执行人姓名">
                <div style="width: 500px;">
                  {{ formData.executorName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="执行人电话">
                <div style="width: 500px;">
                  {{ formData.executorPhone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="执行人职位">
                <div style="width: 500px;">
                  {{ formData.executorPosition }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注信息">
                <div style="width: 500px;">
                  {{ formData.remark }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="录入时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="住户身份二维码" v-if="formData.codeUrl !== ''">
                <div style="width: 500px;">
                  <span>
                    <img :src="formData.codeUrl" />
                  </span>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { focusInfo } from '@/api/focus'
export default {
  name: 'SeeFocus',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {}
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    focusInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      focusInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
