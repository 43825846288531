import request from '@/utils/request'
const baseURL = '/baseapi'
const focusApi = {
  FocusList: '/admin/focus/list',
  FocusInfo: '/admin/focus/focusInfo',
  FocusById: '/admin/focus/focusById',
  SaveFocus: '/admin/focus/saveFocus',
  DelFocus: '/admin/focus/delFocus',
  CheckFocus: '/admin/focus/checkFocus',
  SeachFocus: '/admin/focus/seachFocus'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function focusList (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.FocusList,
    method: 'post',
    data: parameter
  })
}

export function seachFocus (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.SeachFocus,
    method: 'post',
    data: parameter
  })
}

export function focusInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.FocusInfo,
    method: 'post',
    data: parameter
  })
}

export function checkFocus (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.CheckFocus,
    method: 'post',
    data: parameter
  })
}

export function focusById (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.FocusById,
    method: 'post',
    data: parameter
  })
}

export function saveFocus (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.SaveFocus,
    method: 'post',
    data: parameter
  })
}

export function delFocus (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.DelFocus,
    method: 'post',
    data: parameter
  })
}
